.root {
    text-align: initial;
}

.card {
    width: 100% !important;
    text-align: left;
}

.card > div:after {
    content: none !important;
}